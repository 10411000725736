@import "../mixins/variables";
@import "../mixins/clearfix";
@import "../mixins/media";
//変数
@mixin width-s($width) {
  width: calc(#{$width} / 575 * 100%);
}
@mixin width-d($width) {
  width: calc(#{$width} / 1150 * 100%);
}
@mixin padding-s($padding1, $padding2) {
  padding: calc(#{$padding1} / 575 * 100%) calc(#{$padding2} / 575 * 100%);
}
@mixin padding-d($padding1, $padding2) {
  padding: calc(#{$padding1} / 1150 * 100%) calc(#{$padding2} / 1150 * 100%);
}
$main-bg-color: #fff4ec;
$color-text: #252525;
$color-bg_1: #B77153;
$color-bg_2: #541415; 
$color-bg_2cc: #541415cc; 
$color-bg_3: #CCB7A7;
$color-bg_3cc: #CCB7A7cc;
$color-bg_4: #C08B63;
$color-bg_4cc: #C08B63cc;
$color-link: #5f5147;

$color-btn-on: #541415c4;
$spa-bg_color: #f3e8de;
$spa-bg_color2: #dcc5b5;
$spa-bg_color3: #f9f5f1;
$color-rb: #FFF6F0;
$color-p: #e0d8ea;
$color-3: #5F5147;
$color-4: #D5C6B9;
$color-g: #aab177;
/*
テキスト

Styleguide 1.0
*/
body {
  color: $color-text;
  font-weight: 500;
}
h1 {
  font-size: 20px;
  font-size: 1.2rem;
  font-weight: 500;
  @include media_tablet {
    font-size: 26px;
    font-size: 1.6rem;
  }
  @include media_desktop {
    font-size: 36px;
    font-size: 2.2rem;
  }
}
h2, h3 {
  font-size: 20px;
  font-size: 1.2rem;
  margin-bottom: 12px;
  @include media_tablet {
    font-size: 22px;
    font-size: 1.4rem;
  }
  @include media_desktop {
    font-size: 32px;
    font-size: 2rem;
  }
}
h4 {
  font-size: 16px;
  @include media_desktop {
    font-size: 20px;
    margin-bottom: 26px;
  }
}
p {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 20px;
  @include media_desktop {
    font-size: 16px;
  }
}
a {
  color: $color-link;
}
.t-area {
  padding: calc(100 / 575 * 100%) 0;
  position: relative;
  @include media_desktop {
    padding: calc(120 / 1150 * 100%) 0;
  }
}
.t-area__b {
  padding-bottom: calc(100 / 575 * 100%);
  position: relative;
  @include media_desktop {
    padding-bottom: calc(120 / 1150 * 100%);
  }
}
.t-area__t {
  padding-top: calc(100 / 575 * 100%);
  position: relative;
  @include media_desktop {
    padding-top: calc(120 / 1150 * 100%);
  }
}
.t-area__mb {
  margin-bottom: calc(100 / 575 * 100%);
  @include media_desktop {
    margin-bottom: calc(120 / 1150 * 100%);
  }
}
.t-area__pc-t {
  padding: calc(100 / 575 * 100%) 0;
  position: relative;
  @include media_desktop {
    padding-top: calc(120 / 1150 * 100%) ;
    padding-bottom: 0;
  }
}
.t-box1 {
  width: calc(450/ 575 * 100%);
  margin:0 auto;
  position: relative;
  @include media_desktop {
    width: calc(600/ 1150 * 100%);
  }
}
.t-box2 {
  width: calc(450/ 575 * 100%);
  margin:0 auto;
  position: relative;
  @include media_desktop {
    min-width: 250px;
  }
}
.t-box {
  width: calc(450/ 575 * 100%);
  margin:0 auto;
  position: relative;
  padding-bottom: calc(50 / 575 * 100%);
  @include media_tablet {
    width: calc(600/ 1150 * 100%);
    padding-bottom: calc(100 / 1150 * 100%);
  }
}
.btn-area {
  @include width-s(450);
  margin: 0 auto;
  @include media_tablet {
    @include width-d(550);
  }
}
.btn-area__0 {
  margin: 0 auto;
}
.mb-6 {
  margin-bottom: calc(60 / 575 * 100%);
  @include media_desktop {
    margin-bottom: calc(100 / 1150 * 100%);
  }
}
.sp-none {
  display: none;
  @include media_tablet {
    display: block;
  }
}
.br-sp {
  @include media_desktop {
    display: none;
  }
}

.text-center_left {
  @include media_tablet {
  text-align: center;
  .text {
    display: inline-block;
    text-align: left;
    }
  }
}

.font-075 {
  font-size: 0.75em;
  @include media_desktop {
    font-size: 1rem;
  }
}

.rubi {
  font-size: 10px;
}
/*
img拡大縮小
*/

img {
  max-width: 100%;
}

.ec-headerTitle__title img {
  width: 50%;
  padding-top: 18px;
  @include media_desktop {
    width: 84%;
    padding-top: 0;
    padding-bottom: 12px;
  }
}

.wi-60 img {
  width: 60%;
  @include media_desktop {
    width: 100%;
  }
}
.wi-80-pc img {
  @include media_desktop {
    width: 80%;
  }
}
.wi-90 img {
  width: 90%;
}
/*
hover
*/
a:hover {
  text-decoration: none;
}
.anime_hover img,
.anime_hover p {
  transition-duration: 0.3s;
  &:hover {
    opacity: 0.7;
    transition-duration: 0.3s;
  }
}

/*
アニメーション
*/
.fade-in {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
}

.fade-in-y {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in-y 1s ease-in;
    @keyframes fade-in-y {
      0% {
        opacity: 0;
        transform : translate(0, 20px);
      }
      100% {
        opacity: 1;
        transform : translate(0, 0);
      }
    }
  }
}
.fade-in-up-down {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in-up-down 1s ease-in;
    @keyframes fade-in-up-down {
      0% {
        opacity: 0;
        transform : translate(0, -150px);
      }
      100% {
        opacity: 1;
        transform : translate(0, 0);
      }
    }
  }

}
.fade-in-left {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in-left 1s ease-in;
    @keyframes fade-in-left {
      0% {
        opacity: 0;
        transform : translate(-50px, 0);
      }
      100% {
        opacity: 1;
        transform : translate(0, 0);
      }
    }
  }
}
.fade-in-right {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in-right 1s ease-in;
    @keyframes fade-in-right {
      0% {
        opacity: 0;
        transform : translate(50px, 0);
      }
      100% {
        opacity: 1;
        transform : translate(0, 0);
      }
    }
  }
}
.scale-up {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: scale-up 1s ease-in;
    @keyframes scale-up {
      0% {
        opacity: 0;
        transform: scale(0.8);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
.scale-down {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: scale-down 1s ease-in;
    @keyframes scale-down {
      0% {
        opacity: 0;
        transform: scale(1.1);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
.blur-top {
  &.show {
    animation: blur-top 3s;
    @keyframes blur-top {
      0% {
        filter: blur(25px);
        transform: scale(0.8);
      }
      100% {
        filter: blur(0);
        transform: scale(1);
      }
    }
  }

}
.blur {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: blur 1s;
    @keyframes blur {
      0% {
        opacity: 0;
        filter: blur(15px);
        transform : translate(0, 20px);
      }
      100% {
        opacity: 1;
        filter: blur(0);
        transform : translate(0);
      }
    }
  }
}
.sepia {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: sepia 3s;
    @keyframes sepia {
      0% {
        opacity: 0;
        filter: sepia(100%);
      }
      25% {
        opacity: 1;
      }
      100% {
        filter: sepia(0);
      }
    }
  }
}
.left-scale {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: left-scale 1s;
    @keyframes left-scale {
      0% {
        transform: scaleX(0);
        transform-origin: 0% 0%;
      }
      100% {
        transform: scaleX(1);
        transform-origin: 0% 0%;
      }
    }
  }
}
.fade-aaa-1 {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in-a1 1s ease-in;
    @keyframes fade-in-a1 {
      0% {
        opacity: 0;
        transform : translate(-100px, 0);
      }
      100% {
        opacity: 1;
        transform : translate(0, 0);
      }
    }
  }
}
.fade-aaa-2 {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in-a2 1s ease-in 0.5s both;
    @keyframes fade-in-a2 {
      0% {
        opacity: 0;
        transform : translate(0, 100px);
      }
      100% {
        opacity: 1;
        transform : translate(0, 0);
      }
    }
  }
}
.fade-aaa-3 {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in-a3 1s ease-in 1s both;
    @keyframes fade-in-a3 {
      0% {
        opacity: 0;
        transform : translate(0, -100px);
      }
      100% {
        opacity: 1;
        transform : translate(0, 0);
      }
    }
  }
}
.fade-aaa-4 {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in-a4 1s ease-in 1.5s both;
    @keyframes fade-in-a4 {
      0% {
        opacity: 0;
        transform : translate(100px, 0);
      }
      100% {
        opacity: 1;
        transform : translate(0, 0);
      }
    }
  }
}
.fade-aaa-5 {
  opacity: 0;
  &.show {
    opacity: 1;
    animation: fade-in 1s ease-in 1.5s both;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes salon-img {
  0% {
    opacity: 0;
    transform: scale(1.05);
    filter: blur(10px);
  }
  50% {
    opacity: 1;
    filter: blur(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes sen-left-scale {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 0%;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
  }
}
@keyframes sen-down-scale {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
  }
}
@keyframes img-left-slide {
  100% {
    transform : translateX(100%);
  }
}
@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform : translate(-50px, 0);
  }
  100% {
    opacity: 1;
    transform : translate(0, 0);
  }
}
.arrow-o {
  opacity: 0;
  &.show {
    opacity: 1;
    width: 80px;
    height: 20px;
    border-bottom: 2px solid $color-4;
    border-right: 5px solid $color-4;
    transform: skew(45deg);
    position:absolute;
    bottom:10%;
    right:10%;
    animation: arrow-o 1s ease-in-out infinite;
    @keyframes arrow-o {
      0%{right:11%;}
      50%{right:13%;}
      100%{right:11%;}
    }
  }
}
.arrow-x {
  opacity: 0;
  &.show {
    opacity: 1;
    width: 80px;
    height: 20px;
    border-bottom: 1px solid #FFF;
    border-right: 1px solid #FFF;
    transform: skew(45deg);
    position:absolute;
    bottom:10%;
    right:10%;
    animation: arrow-x 1s ease-in-out infinite;
    @keyframes arrow-x {
      0%{right:11%;}
      50%{right:13%;}
      100%{right:11%;}
    }
  }
}
@keyframes sen1{
  0%{
    fill: transparent;
    stroke-dasharray: 2000px;
    stroke-dashoffset: 2000px;
  }
  100%{
    stroke-dashoffset: 0;
  }
}
@keyframes menu_anime {
  0% {
    clip-path: inset(0 100% 100% 0);
    opacity: 0;
  }
  100% {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

/*
ボタン
Styleguide 2.0
*/
.ec-inlineBtn--action,
.ec-blockBtn--action {
  background-color: $color-bg_2;
  border-color: $color-bg_2;
  color: #FFFFFF;
  &:hover,
  &:active,
  &focus {
    background-color: $color-bg_2cc;
    border-color: $color-bg_2cc;
  }
}
.ec-inlineBtn--action:active:focus, .ec-inlineBtn--action:active:hover,
.ec-blockBtn--action.focus, .ec-blockBtn--action:focus {
  background-color: $color-bg_2cc;
  border-color: $color-bg_2cc;
  color: #ffffff;
}

.ec-blockBtn--action.active, .ec-blockBtn--action:active, .open>.ec-blockBtn--action.dropdown-toggle {
  background-color: $color-bg_2cc;
  border-color: $color-bg_2cc;
  color: #ffffff;
}
.ec-blockBtn--action[disabled]:hover {
  background-color: $color-bg_2cc;
  border-color: $color-bg_2cc;
}

.ec-blockBtn--action.active.focus, .ec-blockBtn--action.active:focus, .ec-blockBtn--action.active:hover, .ec-blockBtn--action:active.focus, .ec-blockBtn--action:active:focus, .ec-blockBtn--action:active:hover, .open>.ec-blockBtn--action.dropdown-toggle.focus, .open>.ec-blockBtn--action.dropdown-toggle:focus, .open>.ec-blockBtn--action.dropdown-toggle:hover {
  background-color: $color-bg_2cc;
  border-color: $color-bg_2cc;
  color: #ffffff;
}
.ec-blockBtn--cancel.active.focus, .ec-blockBtn--cancel.active:focus, .ec-blockBtn--cancel.active:hover, .ec-blockBtn--cancel:active.focus, .ec-blockBtn--cancel:active:focus, .ec-blockBtn--cancel:active:hover, .open>.ec-blockBtn--cancel.dropdown-toggle.focus, .open>.ec-blockBtn--cancel.dropdown-toggle:focus, .open>.ec-blockBtn--cancel.dropdown-toggle:hover {
  background-color: $color-bg_3cc;
  border-color: $color-bg_3cc;
  color: $color-text;
}

.ec-blockBtn,
.ec-inlineBtn,
.ec-inlineBtn--cancel,
.ec-blockBtn--cancel {
  background-color: $color-bg_3;
  border-color: $color-bg_3;
  color: $color-text;
  &:hover,
  &:active,
  &:focus {
    background-color: $color-bg_3cc;
    border-color: $color-bg_3cc;
    color: $color-text;
  }
}
.ec-headerNaviRole,
.ec-guest .ec-guest__actions a {
  color: $color-text;
}
.ec-navlistRole .ec-navlistRole__item a:hover {
  background: $color-bg_4cc;
}

.btn-text__arrow {
  @include width-s(350);
  min-width: 200px;
  max-width: 300px;
  margin: 0 auto;
  margin-top: calc(80 / 575 * 100%);
  @include media_tablet {
    @include width-d(450);
    margin-top: calc(100 / 1150 * 100%);
  }
  .btn-top__text {
    max-width: 100%;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 20px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; 
    z-index: 50;
  }
  .btn-top__1 {
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
  }
  .btn__2 {
    background: #395101;
  }
  .btn__3 {
    background: #9d6447;
  }
  .btn_t {
    max-width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    background: $color-bg_2;
    padding: 15px 20px;
    border-radius: 25px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; 
    z-index: 50;
    &:active {
      background-color: $color-bg_2cc;
      border-color: $color-bg_2cc;
      color: #ffffff;
    }
    .arrow-icon {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin-left: 8px;
      position: relative;
      background-size: contain;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(../img/top2/svg/arrow.svg); 
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
} 
.btn_c {
  position: relative;
  max-width: 100%;
  border-radius: 100%;
  padding: 16px;
  color: $color-rb;
  background: $color-3;
  text-align: center;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center; 
  //box-shadow: 0 0 0 1px $color-4, 2px 2px 8px 1px $color-4;
	box-sizing: border-box;
  z-index: 50;
  &::before {
    display: block;
    content: '';
    padding-top: 100%;
  }
  &:active {
    color: #fff;
    background-color: $color-btn-on;
    border-color: $color-btn-on;
  }
  img {
    width: 80%;
  }
}
.btn:hover {
  color: #fff;
  transition-duration: 0.3s;
}
.btn-policy {
  width: 100%;
  color: #fff;
  background: $color-bg_4;
  border: 1px solid $color-bg_4;
  padding: 20px; 
  margin-bottom: 40px; 
  &:hover {
  background: $color-bg_4cc;
  border: 1px solid $color-bg_4cc;
  }
  img {
    width: 84%;
    @include media_tablet {
      width: 56%;
    }
  }
}
.btn-contact {
  width: 100%;
  color: #fff;
  background: $color-bg_2;
  border: 1px solid $color-bg_2;
  padding: 20px; 
  &:hover {
    background: $color-bg_2cc;
    border: 1px solid $color-bg_2cc;
  }
  img {
    width: 90%;
    @include media_tablet {
      width: 60%;
    }
  }
}
.btn-close {
  background-color: $color-bg_3;
  border-color: $color-bg_3;
  color: #fff;
  &:hover {
    background-color: $color-bg_3cc;
    border-color: $color-bg_3cc;
  }
}
.btn-coupon {
  border: 1px solid $color-bg_4;
  background: $color-bg_4;
  color: #fff;
}
/* 円 */
.circle {
    max-width: 300px;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      display: block;
      content: '';
      padding-top: 100%;
    }
    &__p {
      background: $color-p;
    }
    &__o {
      background: $color-4;
    }
}

/*
header
*/
.ec-headerSearch .ec-headerSearch__category .ec-select.ec-select_search {
  background: $color-rb;
}
.ec-headerSearch .ec-headerSearch__keyword {
  color: #525263;
  border: 1px solid #ccc;
  background-color: #f6f6f6;
}
.ec-headerNav .ec-headerNav__itemIcon {
  color: $color-text;
}
.ec-drawerRole {
  background: $color-rb;
  .ec-headerLinkArea {
    background: $color-rb;
  }
}
.ec-headerNavSP {
  background: transparent;
  color: $color-text;
  border: 1px solid $color-text;
}
.ec-drawerRoleClose {
  background: $color-rb;
}
.ec-drawerRole .ec-headerLinkArea .ec-headerLink__item{
  color: $color-text;
}
.ec-cartNavi {
  color: $color-text;
}
.ec-headerRole {
  padding-top: 0;
}
/*
nav
*/
.ec-categoryNaviRole {
  @include media_desktop {
    padding-bottom: 20px;
  }
}
.ec-itemNav__logo {
  width: 50%;
  margin: 40px auto;
}
.ec-itemNav__nav li {
  padding: 0 0.5px;
  a {
    background: none;
    @include media_desktop {
      position: relative;
      transition: color .2s ease;
      font-size: 18px;
      font-weight: 500;
      &:hover {
        background-color: transparent;
        color: #a2988f;
        &::before,
        &::after {
          width: 100%;
          left: 0;
        }
      }
      &::before {
        transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
        background: $color-text;
        content: '';
        position: absolute;
        top: calc(100% - 8px);
        width: 0;
        right: 0;
        height: 3px;
      }
      &::after {
        transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
        background: #a2988f;
        content: '';
        position: absolute;
        top: calc(100% - 8px);
        width: 0;
        right: 0;
        height: 3px;
      }
    }
  }
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav li a {
  background: $color-rb;
  color: $color-text;
  font-weight: 700;
  padding: 20px; 
  span {
    margin-left: 13px;
  }
}
.ec-drawerRole .ec-headerCategoryArea .ec-itemNav__nav>li:hover>a {
  background: $color-rb;
}
/* カート */
.ec-cartRole {
  color: $color-text;
}
.ec-select select {
  background: #ffffff;
}
.ec-cartNavi {
  @include media_desktop {
    background: #ffffff;
  }
}
.ec-cartNaviNull {
  background: #ffffff;
}
.ec-cartNaviNull .ec-cartNaviNull__message {
  color: #de5d50;
  background: #fff;
}
.ec-alert-warning {
  background: #de5d50;
}
.ec-cartNaviIsset {
  background: #ffffff;
  z-index: 50;
  @include media_desktop {
    &::before {
      border-color: transparent transparent #ffffff transparent;
    }
  }
}
.ec-progress .ec-progress__number,
.ec-progress .ec-progress__item:after {
  background: $color-bg_2;
}
.ec-progress .is-complete .ec-progress__number {
  background: $color-bg_1;
}
.ec-progress .is-complete .ec-progress__label{
  color: $color-bg_1;
}
.ec-rectHeading h1, .ec-rectHeading h2, .ec-rectHeading h3, .ec-rectHeading h4, .ec-rectHeading h5, .ec-rectHeading h6 {
  background: #ffffff;
}
.ec-totalBox {
  background: #ffffff;
}
/*パンくず*/
.breadcrumb {
  background: $color-rb;
  font-size: 10px;
  margin-bottom: 0;
  @include media_tablet {
    font-size: 12px;
  }
}
// ページトップへ
.ec-blockTopBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: $color-3;
  right: 15px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  @include media_tablet {
    width: 70px;
    height: 70px;
  }
  img {
    width: 40%;
  }
}

/*
フッター
*/
.wave-footer {
  margin-top: 25px;
  @include media_desktop {
    margin-top: 50px;
  }
  svg {
    transform: translateY(10px);
    vertical-align: baseline;
  }
}
.ec-footerRole {
  background: $color-bg_4;
  margin-top: 0;
  border: none;
  @include media_desktop {
    margin-top: 0;
  }
  .ec-footerNavi__link {
    padding-bottom: calc(30 / 575 * 100%);
    a {
      display: inline;
      border: none;
      padding: 0;
    }
  }
}
.footer_svg {
  @include width-s(60);
  @include padding-s(60, 0); 
  margin: 0 auto;
}
.ec-footerNavi{
  color: $color-text;
}
.ec-footerTitle{
  color: $color-text;
}
/*ヒストリー*/
.ec-historyRole{
  & &__detail{
    @include borderTop;
    width: 100%;

    .ec-historyRole__detailTitle {
      font-size: 1rem;
    }

    .ec-historyRole__detailPrice {
      font-size: 1rem;
    }
  }
}
/*
フォーム

Styleguide 3.0
*/
.ec-zipInputHelp span {
  color: $color-link;
}

/*
グリッド

Styleguide 5.0
*/

/*
ログイン

Styleguide 6.0
*/
.ec-role {
  color: $color-text;
}
.ec-login,
.ec-guest {
  background: #ffffff;
}
.ec-link {
  color: $color-link;
  font-weight: bold;
  &:hover {
    color: $color-link;
  }
}
/*
商品

Styleguide 7.0
*/
.ec-searchnavRole .ec-searchnavRole__infos,
.ec-shelfRole,
.ec-headingTitle,
.ec-productRole {
  color: $color-text;
}
.ec-shelfGrid .ec-shelfGrid__item-image {
  height: auto;
  @include media_desktop {
    height: auto;
  }
}
.ec-topicpath,
.ec-productRole .ec-productRole__category a {
  color: $color-link;
  font-weight: bold;
}

.ec-productRole .ec-productRole__category a
.ec-pager .ec-pager__item--active {
  color: $color-link;
  background: #ffffff;
}
.ec-pager .ec-pager__item:hover,
.ec-pager .ec-pager__item--active {
  background: #ffffff;
}
.card-header {
  background: $color-bg_3;
}
/*
ポップアップ
*/
 /* ポップアップのスタイル */
.line-popup {
  position: fixed;
  bottom: -300px;
  right: 20px;
  width: 250px;
  padding: 20px;
  background-color: #b7cd91f2;
  border-radius: 25px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
  transition: bottom 1s ease-in-out;
  z-index: 1000;
}
.line-popup-content {
  text-align: center;
  .line-logo-ios {
    width: 60px;
    margin-bottom: 14px;
  }
}

/* ポップアップが表示されるときのクラス */
.line-popup.show {
  bottom: 20px;
}

/* 閉じるボタンのスタイル */
.line-popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}


@import "../mixins/media";
/*
共通コンポーネント
Styleguide 11.0
*/
body {
  min-width: 320px;
  animation: fade-in 3s;
  background: $main-bg-color;
}
.ec-layoutRole {
  background: $main-bg-color;
}
.bg-rb {
  background: $color-rb;
}
.bg-g {
  background: $color-g;
}
.bg-o {
  background: $color-4;
}
.text-g {
  color: $color-g;
}
.bg-1 {
  background-color: $color-bg_1;
}
.bg-2 {
  background-color: $color-bg_2;
}
.bg-3 {
  background-color: $color-bg_3;
}
.bg-4 {
  background-color: $color-bg_4;
}
.bg-img2 {
  background-image: url(../img/top2/top-content2.jpg); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-img3 {
  background-image: url(../img/top2/top-content3.jpg); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.text-color__w {
  color: #fff;
}
.top-title {
  font-size: 2.2rem;
  @include media_desktop {
    font-size: 2.2rem;
  }
  span {
    font-size: 1.8rem;
  }
}
.top-title2 {
  font-size: 1.6rem;
  @include media_desktop {
    font-size: 1.8rem;
  }
}
.m-title {
  position: relative;
  @include width-s(450);
  text-align: center;
  margin: 0 auto;
  margin-bottom: calc(80 / 575 * 100%);
  z-index: 30;
  @include media_desktop {
  @include width-d(600);
    margin-bottom: calc(80 / 1150 * 100%);
  }
  img {
    margin-bottom: calc(20 / 575 * 100%);
  }
  h1 {
    span {
      font-size: 1.1rem;
      @include media_desktop {
        font-size: 1.25rem;
      }
    }
  } 
  h2, h3 {
    span {
      font-size: 1rem;
      @include media_desktop {
        font-size: 1.2rem;
      }
    }
  }
  & &__svg {
    + h2, 
    + h3 {
      font-size: 14px;
      @include media_desktop {
        font-size: 18px;
      }
    }
  }
}
.sub-svg-title {
  @include width-s(500);
  @include media_desktop {
    @include width-d(600);
  }
}
/*
トップページ
Styleguide 12.0
*/

.ec-sliderRole {
  padding-left: 0;
  padding-right: 0;
}
/* top-page2025 */
.a-us {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 14px;
  @include media_desktop {
    font-size: 2.4rem;
  }
  span {
    font-size: 1.6rem;
  }
}
.top-content {
  position: relative;
  display: flex;
  flex-direction: column;
  @include media_desktop {
    flex-direction: row;
    align-items: flex-end;
  }
  &__item {
    position: relative;
    width: 100%;
    text-align: center;
    padding: calc(100 / 575 * 100%) 0;
    @include media_desktop {
      flex: 1;
      padding: calc(150 / 1150 * 100%) 0;
    }
  } 
  &__text-shadow {
    text-shadow: 1px 1px 2px #000000c7;
  }
}
.top-henna_content {
  position: relative;
  max-width: 100%;
  z-index: 30;
  display: flex;
  gap: 34px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 1rem;
  padding: 1rem;
  &__img {
    flex: 0 0 200px;
    scroll-snap-align: start;
    @include media_desktop {
      flex: 0 0 280px;
    }
    a {
      color: #fff;
    }
    img {
      border-radius: 25px;
      &:active {
        transform: scale(1.1, 1.1);
        transition-duration: 0.5s;
      }
    }
  }
  &__text {
    padding-top: 18px;
    h3, h4, span {
      font-size: 14px;
    }
    span {
      font-weight: 500;
    }
    
  }
}

/* news */
.ec-newsRole {
  padding: 60px 0 0;
  @include media_desktop {
    padding: 100px 0 0;
  }
  .ec-newsRole__news {
    padding: 0 calc(50 / 575 * 100%);
    @include media_desktop {
      max-width: 1000px;
      border: none;
      margin: 0 auto;
    }
  }
  .ec-newsRole__newsTitle {
    color: $color-text;
  }
  .ec-newsRole__newsDescription a {
    color: $color-link;
  }
  .ec-newsRole__newsCloseBtn {
    background: $color-bg_2;
    color: #fff;
  }
}
.ec-newsRole__newsClose a:not([href]):hover {
    color: $color-rb;
}
/* journal-top */
.journal-border {
  height: 50px;
}
.journal-vol__num {
  p {
    font-size: 1.4rem;
  }
  span {
    font-size: 3rem;
  }
}
.journal-title {
  color: #fff;
  @include media_desktop {
    font-size: 1.6rem;
  }
}
/* journal-summary */
.journal-paper {
  border: 2px solid $color-text;
  padding: 20px;
  p {
    margin: 0;
  }
}

/* column-top */
.column-top {
  &__flex {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0 1rem;
    @include media_desktop {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 3.4rem 4.3%;
      margin: 0 4rem;
    }
    article {
          flex: 0 0 100%;
          margin-top: 1.3rem;
          padding-top: 1.3rem;
          border-top: 1px solid $color-4;
          @include media_desktop {
            flex: 0 0 47.8%;
        }
        a {
          color: $color-text;
        }
    }
  }
  &__link {
    display: grid;
    grid-template-columns: 48% auto 1fr;
    grid-template-rows: repeat(3, auto);
    gap: .5rem 0;
    @include media_desktop {
      position: relative;
      display: block;
    }
  }
  &__time-date {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    text-align: left;
    padding-top: .1rem;
    margin-bottom: .2rem;
    @include media_desktop {
      font-size: 1rem;
      text-align: right;
      height: 1.5rem;
      font-weight: 400;
    }
  }
  &__img {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    margin-bottom: 0;
    @include media_desktop {
    margin-bottom: .8rem;
    width: 100%;
    position: relative;
    }
    figure {
      width: 90%;
      margin: 0;
      @include media_desktop {
        width: 100%;
        height: 0;
        padding-bottom: 61%;
        position: relative;
        margin-bottom: 0;
      }
    }
    img {
      width: 100%;
      @include media_desktop {
        width: 100%;
        height: 100%;
        position: absolute;
        -o-object-fit: cover;
        object-fit: cover;
        top: 0;
        left: 0;
      }
    }
  }
  &__title {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 1em;
    @include media_desktop {
    font-size: 1.15em;
    letter-spacing: .05em;
    font-weight: 400;
    line-height: 1.7;
    border-bottom: 1px solid $color-4;
    margin-bottom: .8rem;
    padding-bottom: .8rem;
    }
  }
  &__hashtag {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 4px;
    padding: 0;
    @include media_desktop {
      gap: 8px;
    }
    li {
      list-style: none;
      background-color: #fff;
      border: 1px solid $color-4;
      border-radius: 50px;
      display: block;
      font-size: .625rem;
      margin: 0;
      min-width: 5em;
      padding: 4px 10px;
      text-align: center;
      @include media_desktop {
        font-size: .8rem;
      }
    }
  }
  &__text {
    display: none;
    @include media_desktop {
      display: block;
      font-size: 1rem;
      letter-spacing: .01rem;
      line-height: 1.6;
    }
  }
}
/* column-page */
.column-page {
  h2 {
    font-weight: bold;
    text-align: center;
    margin-top: 62px;
    margin-bottom: 26px;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 36px;
  }
  h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 36px;
  }
  &__time-date {
    margin: 12px 24px;
    @include media_desktop {
      font-size: 1.2rem;
      text-align: center;
      margin-top: 24px;
    }
  }
  &__hashtag {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin: 12px 24px;
    padding: 0;
    @include media_desktop {
      justify-content: center;
      gap: 8px;
      margin: 24px;
    }
    li {
      list-style: none;
    }
    a {
      color: $color-text;
      background-color: #fff;
      border: 1px solid $color-4;
      border-radius: 50px;
      display: block;
      font-size: .625rem;
      margin: 0;
      min-width: 5em;
      padding: 4px 10px;
      text-align: center;
      @include media_desktop {
        font-size: 1rem;
      }
    }
  }
  &__toc {
    display: table;
    @include width-s(500);
    padding: 20px;
    margin: 0 auto;
    background: #fff;
    border: 1px solid $color-3;
    @include media_desktop {
      @include width-d(600);
    }
    p {
      text-align: center;
      font-size: 1.2rem;
    }
    ol {
      list-style-position: inside;
      padding-inline-start: 12px;
      margin-top: 1rem;
      li {
        color: $color-3;
        margin-bottom: 1.2rem;
      }
      ul {
        margin-top: 1rem;
        li {
          list-style: none;
          position: relative;
          margin-bottom: 1rem;
          ::before {
            content: '';
            display: block;
            position: absolute;
            top: 12px;
            left: -18px;
            width: 10px;
            height: 1px;
            background: $color-3;
          }
        }
      }
    }
    a {
      color: $color-3;
    }
  }
  &__img {
    text-align: center;
    img,
    video {
      @include width-s(450);
      margin: 0 auto;
      @include media_desktop {
      @include width-d(700);
      } 
    }
  }
  &__border {
    border: 2px solid $color-4;
    padding: 1rem;
  }
}
/* RECOMMEND */
.recommend {
  &__h2 {
    @include width-s(450);
    text-align: center;
    margin: 0 auto;
    margin-bottom: calc(40 / 575* 100%);
    z-index: 30;
    @include media_desktop {
      @include width-d(600);
      margin-bottom: calc(40 / 1150* 100%);
    }
  }
  &__flex {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0 1rem;
    @include media_desktop {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 3.4rem 4.3%;
      margin: 0 4rem;
    }
    .recommend__content {
        flex: 0 0 100%;
        margin-top: 1.3rem;
        padding-top: 1.3rem;
        border-top: 1px solid $color-4;
        @include media_desktop {
          flex: 0 0 30.4%;
          border: none;
      }
      a {
        color: $color-text;
      }
    }
  }
  &__link {
    display: grid;
    grid-template-columns: 48% auto 1fr;
    grid-template-rows: repeat(3, auto);
    gap: .5rem 0;
    @include media_desktop {
      position: relative;
      display: block;
    }
  }
  &__img {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    margin-bottom: 0;
    @include media_desktop {
    margin-bottom: .8rem;
    width: 100%;
    position: relative;
    }
    figure {
      width: 90%;
      margin: 0;
      @include media_desktop {
        width: 100%;
        height: 0;
        padding-bottom: 61%;
        position: relative;
        margin-bottom: 0;
      }
    }
    img {
      width: 100%;
      @include media_desktop {
        width: 100%;
        height: 100%;
        position: absolute;
        -o-object-fit: cover;
        object-fit: cover;
        top: 0;
        left: 0;
      }
    }
  }
  &__title {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 1em;
    @include media_desktop {
    font-size: 1.15em;
    letter-spacing: .05em;
    font-weight: 400;
    line-height: 1.7;
    margin-bottom: .8rem;
    padding-bottom: .8rem;
    }
  }
}
.recommend a {
  color: $color-text;
}

/* PRODUCT BENEFITS */
.benefit-content {
  h2 {
    font-size: 1.2rem;
  }
}

/* special henna_color */
.henna-color-item {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: calc(100 / 575 * 100%);
  @include media_desktop {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    margin-bottom: calc(100 / 1150 * 100%);
  }
  &__img {
    @include width-s(400);
    margin-bottom: calc(50 / 575 * 100%);
    @include media_desktop {
      @include width-d(400);
      margin-bottom: 0;
    }
  }
  &__text {
    @include width-s(400);
    margin: 0 auto; 
    position: relative;
    @include media_desktop {
      @include width-d(500);
      margin: 0;
    }
  }
  &__svg {
    @include width-s(250);
    position: absolute;
    top: 40%;
    right: 10%;
    font-size: 1.2rem;
    @include media_desktop {
      @include width-d(300);
      font-size: 1.6rem;
      top: 10%;
      right: 23%;
    }
  }
  &__rev {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: calc(100 / 575 * 100%);
    @include media_desktop {
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: space-around;
      margin-bottom: calc(100 / 1150 * 100%);
    }
    .henna-color-item__img {
      margin-left: auto;
      @include media_desktop {
        margin: 0;
      }
    }
    .henna-color-item__svg {
      left: 15%;
      @include media_desktop {
        left: 5%;
      }
    }
  }
  &__title {
    border-top: 1px solid $color-text;
    border-bottom: 1px solid $color-text;
    @include padding-s(40, 80);
    text-align: center;
    @include media_tablet {
      @include padding-d(40, 80);
    }
    h3 {
      margin: 0;
    }
  }
}
.special-comment {
  @include width-s(450);
    @include padding-s(50, 40);
    margin-top: calc(60 / 575 * 100%);
    margin-left: auto;
    background: $color-bg_3; 
    @include media_desktop {
      @include width-d(650);
      @include padding-d(50, 150);
      margin-top: calc(70 / 1150 * 100%);
    }
  p {
    margin: 0;
  }
}
.henna-step {
  position: relative;
  .step-img {
    @include width-s(300);
    margin-left: auto;
    @include media_desktop {
      @include width-d(500);
      margin-top: 9%;
      margin-left: 48%;
      margin-bottom: calc(200 / 1150 * 100%);
    }
  }
  .step-list {
    @include width-s(450);
    height: calc(300 / 575 * 100%);
    max-height: 240px;
    background: $color-bg_1;
    @include padding-s(40, 40);
    margin-top: calc(-60 / 575 * 100%);
    @include media_tablet {
      max-height: 260px;
    }
    @include media_desktop {
      @include width-d(600);
      height: calc(540 / 1150 * 100%);
      max-height: 300px;
      @include padding-d(40, 40);
      margin-top: calc(-400 / 1150 * 100%);
      margin-left: 10%;
    }
    h2 {
      color: #fff;
    }
    .list-text {
      position: relative;
      @include padding-s(20, 20);
      &:before {
        content: '';
        position: absolute;
        top: 8%;
        left: 1%;
        width: 1px;
        height: 80%;
        background: $color-bg_1;
      }
      p {
        font-size: 1.05rem;
        color: #fff;
        padding-left: calc(30 / 575 * 100%);
        @include media_desktop {
          font-size: 1.2rem;
          padding-left: calc(220 / 1150 * 100%);
        }
      }
    }
  }
}
.step-item {
  position: relative;
  margin-bottom: calc(160 / 575 * 100%);
  @include media_desktop {
    margin-bottom: calc(250 / 1150 * 100%);
  }
  &__img {
    @include media_desktop {
      @include width-d(550);
      @include padding-d(20, 40);
    }
  }
  &__text {
    @include width-s(400);
    padding-top: calc(30 / 575 * 100%);
    margin: 0 auto;
    position: relative;
    color: #fff;
    @include media_desktop {
    @include width-d(550);
      position: absolute;
      top: 50%;
      left: 40%;
      padding-top: 0;
      z-index: 5;
    }
    &::before {
      content: "";
      position: absolute;
      top: -8%;
      left: 50%;
      transform: translateX(-50%);
      width: 120%;
      height: 124%;
      background: $color-bg_1;
      z-index: 30;
      @include media_desktop {
        height: 120%;
      }
    }
    h3 {
      @include width-s(400);
      font-size: 1.1rem;
      text-align: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 50;
      @include media_desktop {
        position: relative;
        @include width-d(1000);
      }
    }
    p {
      position: relative;
      padding-top: 10px;
      z-index: 50;
      @include media_desktop {
        padding: 0;
      }
    }
  }
}
/* special shampoo */
.shampoo-top {
  &__flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media_desktop {
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
  &__img {
    @include media_desktop {
      @include width-d(500);
    }
  }
  &__text {
    background: $color_bg_4;
    @include width-s(400);
    @include padding-s(40, 20);
    margin-top: calc(-50/ 575 * 100%);
    margin-bottom: calc(100 / 575* 100%);
    @include media_desktop {
      @include width-d(600);
      @include padding-s(40, 40);
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
}
.shampoo-bg {
  background-image: url(../img/contents/special/shampoo/shampoo-bg-s.jpg); 
  background-size: cover;
  @include media_desktop {
    background-image: url(../img/contents/special/shampoo/shampoo-bg.jpg); 
  }
}
.shampoo-voice {
  padding: calc(50 / 575 * 100%) 0;
  @include media_desktop {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding-top: calc(80 / 1150 * 100%);
    padding-bottom: calc(100 / 1150 * 100%);
  }
  &__comment {
    @include width-s(500);
    margin: 0 auto;
    margin-bottom: calc(40 / 575 * 100%);
    @include media_desktop {
      @include width-d(500);
      margin-bottom: 0;
    }
  }
  &__comment__text {
    @include padding-s(20, 20);
    border: 1px solid $color-text;
    border-radius: 25px;
    p {
      margin: 0;
    }
  }
  &__comment__small {
    text-align: right;
  }
}
.shampoo-user {
  &__flex {
    @include media_desktop {
      display: flex;
      justify-content: space-between;
    }
  }
  &__item {
    position: relative;
    z-index: 10;
    @include media_desktop {
      @include width-d(550);
    }
    &.shampoo-user__item1 {
      padding-bottom: calc(100 / 575 * 100%);
      @include media_desktop {
        padding-bottom: 0;
      }
    }
    &__text {
      @include media_desktop {
        @include width-d(700);
      }
      h3 {
        margin-bottom: calc(50 / 575 * 100%);
        text-align: center;
        @include media_desktop {
          font-size: 1.2rem;
          margin-bottom: calc(100 / 1150 * 100%);
        }
      }
    }
  }
  &__img {
    @include width-s(450);
    padding-bottom: calc(100 / 575 * 100%);
    position: relative;
    @include media_desktop {
      @include width-d(800);
      margin: 0 auto;
      padding-bottom: calc(120 / 1150 * 100%);
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 5%;
      right: -5%;
      width: 60%;
      height: 50%;
      background: $color-bg_4;
      z-index: -1;
      @include media_desktop {
        bottom: 10%;
      }
    }
    &.shampoo-user__img1 {
      margin-left: auto;
      &::before {
        left: -15%;
      }
    }
    &.shampoo-user__img2 {
      &::before {
        right: -15%;
      }
    }
  }
}
.shampoo-faq {
  @include media_desktop {
    display: flex;
    justify-content: space-around;
  }
  &__item {
    margin-bottom: calc(100 / 575 * 100%);
    @include media_desktop {
      @include width-d(500);
      margin-bottom: calc(50 / 1150 * 100%);
    }
  }
  &__title {
    position: relative;
    h2 {
      @include media_desktop {
        font-size: 1.2rem;
      }
    }
    &::before {
      content: '';
      display: inline-block;
      background-image: url(../img/contents/special/shampoo/q.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 50px;
      height: 50px;
      margin-bottom: calc(30/ 575 * 100%);
      @include media_desktop {
        width: 80px;
        height: 80px;
        margin-bottom: calc(80/ 1150 * 100%);
      }
    }
  }
  &__text {
    width: 300px;
    padding: 20px 32px;
    margin: auto;
    margin-bottom: calc(50 / 575 * 100%);
    @include media_desktop {
      padding: 20px 20px;
      margin-bottom: 0;
    }
  }
}
/* special oil */
.oil-merit {
  display: flex;
  flex-wrap: wrap;
  @include media_tablet {
    flex-direction: row;
    justify-content: space-around;
  }
  & &__item {
    @include width-s(250);
    margin: 0 auto;
    margin-bottom: 30px;
    &:first-child {
      @include width-s(350);
      @include media_tablet {
        @include width-d(300);
      }
    }
    @include media_tablet {
      @include width-d(300);
      margin-bottom: 0;
    }
    img {
      margin-bottom: 1em;
    }
  }
}
.amla-oil {
  @include media_tablet {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  & &__img {
    @include width-s(450);
    margin: auto;
    margin-bottom: 20px;
    @include media_tablet {
      @include width-d(400);
      margin: 0;
    }
  }
  & &__text {
    @include width-s(400);
    margin: auto;
    @include media_tablet {
      @include width-d(500);
      padding: 0;
      margin: 0; 
    }
  }
}
.oil-how-to {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .circle {
    max-width: 160px;
    background: $color-bg_3;
    padding: 20px;
    margin: 10px;
    font-size: 14px;
    text-align: center;
    @include media_tablet {
      max-width: 200px;
      padding: 30px;
      margin: 20px;
      font-size: 16px;
    }
    @include media_desktop {
      max-width: 285px;
      padding: 40px;
    }
  }
}
.oil-review {
  @include width-s(500);
  @include padding-s(50, 50);
  border: 1px solid $color-text;
  border-radius: 25px;
  margin: auto;
  margin-bottom: calc(50 / 575 * 100%);
  @include media_desktop {
    @include width-d(800);
    @include padding-d(50, 50);
    margin-bottom: calc(100 / 1150 * 100%);
  }
  p {
    margin: 0;
  }
}

/* special clear */
.henna-clear {
  position: relative;
  overflow: hidden;
  &__img {
    @include width-s(500);
    padding-bottom: calc(30 / 575 * 100%);
    margin-left: auto;
    @include media_desktop {
      @include width-d(700);
      padding-bottom: calc(100 / 1150 * 100%);
      margin-top: calc(100/ 1150 * 100%);
      margin-left: calc(100 / 1150 * 100%);
    }
  }
  &__img__sub {
    @include width-s(150);
    position: absolute;
    top: 12%;
    right: 2%;
    @include media_desktop {
      @include width-d(300);
      top: 0;
      right: -2%;
    }
  }
  &__text {
    @include media_desktop {
      margin-left: calc(200 / 1150 * 100%);
    }
  }
  &__comment {
    @include width-s(500);
    @include padding-s(20, 20);
    margin-left: auto;
    background: $color-bg_1;
    color: #fff;
    @include media_desktop {
      @include width-d(750);
      @include padding-d(20, 75);
    }
    p {
      margin: 0;
    }
  }
  &__comment1 {
    @include media_desktop {
      position: absolute;
      top: 48%;
      left: auto;
      right: 0;
    }
  }
  &__comment2 {
    margin-top: 8%;
    @include media_desktop {
      position: absolute;
      top: 35%;
      left: auto;
      right: 0;
    }
  }
}
.comment-small__clear {
  width: 100%;
  margin-top: 10px;
  margin-bottom: calc(80 / 575 * 100%);
  text-align: right;
  line-height: 1;
  small {
    font-size: 10px;
  }
}
.clear-how {
  &__img {
    @include width-s(500);
    margin: auto;
    @include media_desktop {
      @include width-d(700);
      margin-bottom: calc(100 / 1150 * 100%);
    }
  }
  &__text {
    width: 250px;
    padding-top: calc(50 / 575 * 100%);
    margin: 0 auto;
    position: relative;
    z-index: 10;
    @include media_desktop {
      width: 287px;
      padding: 0;
    }
    h2 {
      margin-bottom: 40px;
    }
    p {
      margin-bottom: 2px;
    }
  }
}
.clear-pack {
  position: relative;
  &__img1 {
    @include width-s(450);
    margin-left: auto;
    @include media_desktop {
      @include width-d(700);
      margin-left: calc(100 / 1150 * 100%);
      margin-bottom: calc(100 / 1150 * 100%);
    }
  }
  &__img2 {
    @include width-s(350);
    @include media_desktop {
      @include width-d(430);
    }
  }
}
/*
herb-powder
*/
.herb-powder-howto {
  margin-bottom: 24px;
  text-align: center;
  img {
    @include width-s(450);
    margin: 0 auto;
    @include media_desktop {
      @include width-d(700);
    }
  }
  &__video {
    text-align: center;
    video {
      width: 80%;
      @include media_desktop {
        @include width-d(700);
      }
    }
  }
}

/*
aaaランクヘナ
*/
.aaa-mark {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media_tablet {
    justify-content: center;
  }
  img {
    @include width-s(100);
    @include media_tablet {
      @include width-d(100);
    }
  }
  p {
    width: 220px;
    margin-left: 14px;
    margin-bottom: 0;
    @include media_tablet {
      width: 210px;
    }
  }
}
.aaa_4 {
  display: flex;
  position: relative;
  overflow: hidden;
  @include media_tablet {
    align-items: flex-end;
  }
  &_img {
    position: relative;
    z-index: 10;
    width: 25%;
    @include media_tablet {
      width: 13%;
      margin-left: calc(20 / 1150 * 100%);
    }
  }
  &_svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 46%;
    @include media_tablet {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0);
      width: 30%;
      margin-left: 20px;
    }
  }
}
.aaa_work {
  margin-bottom: calc(100 / 575 * 100%);
  @include media_tablet {
    margin-bottom: calc(100 / 1150 * 100%);
    &__2 {
      margin-left: calc(200 / 1150 * 100%);
    }
  }
  &__title {
    display: flex;
    align-items: baseline;
    transform: translateX(-5%);
    @include media_tablet {
      transform: translateX(0);
    }
    .aaa_bg {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background: $color-bg_3;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media_desktop {
        width: 250px;
        height: 250px;
        font-size: 1.6rem;
      }
    }
    h3 {
      transform: translateX(-50px);
    }
  }
  p {
    margin-top: calc(-50 / 1150 * 100%);
  }
}
.aaa_ec {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media_tablet {
    flex-direction: row;
    justify-content: flex-end
  }
  &__svg {
    width: calc(300 / 575 * 100%);
    padding-bottom: calc(100 / 575 * 100%);
    @include media_tablet {
      width: calc(500 / 1150 * 100%);
      max-width: 250px;
      padding-bottom: 0;
    }
  }
  &__text {
    @include media_tablet {
      margin: 0 calc(100 / 1150 * 100%);
    }
  }
}

/* 20min */
.time20 {
  position: relative;
  overflow: hidden;
  &__leaf {
    @include width-s(400);
    margin-left: auto;
    @include media_tablet {
      @include width-d(500);
      transform: translateX(-20px);
    }
    img {
      transform: translateX(20px) rotate(21deg);
      @include media_tablet {
        transform: translateX(-45px) rotate(21deg);
      }
    }
  }
  &__powder {
    @include width-s(350);
    margin-bottom: calc(100 / 575 * 100%);
    @include media_tablet {
      @include width-d(500);
      margin-top: calc(-200 / 1150 * 100%);
      margin-bottom: calc(100 / 1150 * 100%);
    }
    img {
      transform: translateX(46px) rotate(343deg);
      @include media_tablet {
        transform: translateX(40px) rotate(343deg);
      }
    }
  }
  &__leaf2 {
    @include width-s(480);
    @include media_tablet {
      @include width-d(680);
    }
    img {
      transform: translateX(-53px);
      @include media_tablet {
        transform: translateX(28px);
      }
    }
  }
  &__hair {
    @include width-s(300);
    margin-left: auto;
    margin-bottom: calc(100 / 575 * 100%);
    @include media_tablet {
      @include width-d(350);
      margin-top: calc(-300 / 1150 * 100%);
      margin-bottom: calc(100 / 1150 * 100%);
    }
    img {
      transform: translateX(-33px) rotate(348deg);
      @include media_tablet {
        transform: translateX(-24px) rotate(348deg);
      }
    }
  }
}
/* before-after */
.color-banner {
  display: flex;
  flex-direction: column;
  @include media_desktop {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: calc(100 / 1150 * 100%);
  }
  &__box {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 10px;
    @include media_desktop {
      @include width-d(400);
    }
    h2 {
      font-size: 2.2rem;
      color: #fff;
      text-align: center;
      margin: 0;
    }
  }
  &__orange {
    background: #FD8731;
  }
  &__light-brown {
    background: #E5A16F;
  }
  &__brown {
    background: #B2774B;
  }
  &__dark-brown {
    background: #815737;
  }
  &__soft-black {
    background: #503420;
  }
  &__text {
    @include width-s(400);
    margin: 40px auto;
    @include media_desktop {
      @include width-d(600);
      margin: 0 auto;
    }
  }
}
.color-after__img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: calc(100 / 575 * 100%);
  @include media_tablet {
    margin-bottom: calc(150 / 1150* 100%);
  }
  img {
    @include width-s(250);
    margin-bottom: 40px;
    border-radius: 25px;
    @include media_tablet {
      @include width-d(250);
    }
  }
}

/*
henna-select
*/
.select2 {
  position: relative;
  padding: calc(100 / 575 * 100%) 0;
  margin-bottom: calc(50 / 575 * 100%);
  @include media_desktop {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: calc(100 / 1150 * 100%) 0;
    margin-bottom: 0;
  }
  &__rev {
    flex-direction: row-reverse;
  }
  & &-img {
    @include width-s(500);
    margin: 0 auto;
    margin-bottom: calc(50 / 575 * 100%);
    @include media_desktop {
      @include width-d(500);
      margin: 0;
      margin-bottom: calc(50 / 1150 * 100%);
    }
  }
  & &-text {
    @include width-s(450);
    margin: 0 auto;
    @include media_desktop {
      @include width-d(450);
      margin: 0;
    }
    h2, h3 {
      text-align: center;
      padding: 25px 0;
      @include media_desktop {
        text-align: left;
      }
    }
  }
}
/*
first-henna line-toke
*/
.line-toke {
  @include media_tablet {
    @include padding-d(0, 100);
  }
  @include media_desktop {
    @include padding-d(0, 200);
  }
}
.line-area {
  display: flex;
  justify-content: space-evenly;
}
.line-box {
  width: 70%;
}
.line-icon {
  width: 15%;
  @include media_desktop {
    width: 11%;
  }
  img {
    width: 100%;
    max-width: 80px;
    min-width: 40px;
    border-radius: 50%;
    border: 1px solid;
  }
}
.line-wrap {
  margin-top: calc(50 / 575 * 100%);
  @include media_tablet {
    margin-top: calc(100 / 1150 * 100%);
  }
  h3 {
    font-size: 1.3rem;
    margin-bottom: 24px;
    @include media_tablet {
      font-size: 1.6rem;
    }
  }
}
.says {
  position: relative;
  padding: 10px;
  margin-bottom: 24px;
  border-radius: 12px;
  background: #fffefd;
  box-shadow: 2px 6px 10px #e0e0e0;
}

.says:after {
  content: "";
  position: absolute;
  top: 3px;
  left: -19px;
  border: 8px solid transparent;
  border-right: 18px solid #fffefd;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}
.says p,
.comment p {
  margin: 16px 0;
  padding: 0;
}

.color-bold {
  font-weight: bold;
  color: #ff8282;
}

/* line-voice */

.comment {
  position: relative;
  padding: 10px;
  margin-bottom: 24px;
  border-radius: 12px;
  background: #fffefd;
  box-shadow: -2px 6px 10px #e0e0e0;
}

.comment:after {
  content: "";
  position: absolute;
  top: 3px;
  right: -19px;
  border: 8px solid transparent;
  border-left: 18px solid #fffefd;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}

/*
salon
*/
.salon {
  overflow: hidden;
  p {
    line-height: 2;
  }
}
.salon_top {
  position: relative;
  & &__img {
  position: relative;
  margin-bottom: calc(250 / 575 * 100%);
  @include media_tablet {
    margin-bottom: 0;
  }
  }
  & &__img1 {
    position: relative;
    z-index: 20;
    @include media_tablet {
      @include width-d(900);
    }
    img {
      will-change:animation;
      animation: salon-img 10s;
    }
  }
  & &__img2 {
    @include width-s(400);
    position: absolute;
    bottom: -25%;
    right: 2%;
    z-index: 40;
    @include media_tablet {
      @include width-d(500);
      right: 0%;
      bottom: -34%;
    }
  }
  & &__text {
    @include width-s(450);
    padding-bottom: calc(100 / 575 * 100%);
    margin: 0 auto;
    @include media_tablet {
      @include width-d(570);
      padding: calc(100 / 1150 * 100%) 0;
      margin-left: calc(100 / 1150 * 100%);
    }
  }
  & &__title {
    text-align: center;
    margin-bottom: calc(100 / 575 * 100%);
    @include media_tablet {
      text-align: left;
      margin-bottom: calc(100 / 1150 * 100%);
    }
    span {
      font-size: 1.2rem;
    }
  }
}

.salon_news {
  &__list {
    @include width-s(450);
    border-bottom: 2px solid $color-4;
    margin: 0 auto;
    cursor: pointer;
    @include media_tablet {
      @include width-d(700);
    }
    .acd-title {
      &::after {
        border-top: 2px solid $color-3;
        border-right: 2px solid $color-3;
      }
      .news-time {
        padding-bottom: 10px;
      }
    }
  }
}

.salon_content2 {
  position: relative;
  padding-bottom: calc(150 / 575 * 100%);
  @include media_desktop {
    padding-bottom: calc(100 / 1150 * 100%);
  }
  &__item1 {
    position: relative;
    width: 100%;
  }
  &__img {
    @include width-s(500);
    padding: calc(50 / 575* 100%) 0;
    margin: 0 auto;
    @include media_desktop {
      @include width-d(1100);
      display: flex;
      justify-content: flex-end;
      padding: calc(100 / 1150* 100%) 0;
      &.rev {
        justify-content: flex-start;
      }
    }
  }
  &__item2 {
    @include media_desktop {
      @include width-d(650);
      @include padding-d(50, 100);
      position: absolute;
      top: 40%;
      left: 5%;
      .t-box1 {
        @include width-d(1100);
      }
      &.rev {
        left: 42%;
      }
    }
  }
}
.bg-box {
  width: 60%;
  height: 35%;
  position: absolute;
  top: 0;
  right: 0;
  background: $color-bg_4;
  z-index: 10;
  @include media_tablet {
    height: 45%;
    top: 10%;
  }
}
.salon_content3 {
  position: relative;
  padding-bottom: calc(50 / 575 * 100%);
  @include media_tablet {
    padding-bottom: calc(20 / 1150 * 100%);
  }
  &__item {
    position: relative;
    padding-left: calc(150 / 575 * 100%);
    margin-bottom: calc(100 / 1150 * 100%);
    @include media_tablet {
      padding-left: 0;
    }
  }
  &__img {
    @include width-s(450);
    position: relative;
    z-index: 30;
    @include media_tablet {
      @include width-d(500);
      margin: 0 auto;
    }
  }
  .bg-box-left {
    height: 60%;
    left: 0;
    top: -20%;
    @include media_tablet {
      width: 30%;
      top: -20%;
      left: 14%;
    }
    @include media_desktop {
      width: 42%;
      height: 70%;
    }
  }
  .bg-box-right {
    height: 73%;
    top: 30%;
    @include media_tablet {
      width: 30%;
      right: 14%;
    }
    @include media_desktop {
      width: 41%;
      height: 87%;
      top: 16%;
      right: 7%;
    }
  } 
  &__item2 {
    width: calc(450/ 575 * 100%);
    margin: 0 auto;
    position: relative;
    @include media_tablet {
      width: calc(700/ 1150 * 100%);
    }
    h2 {
      @include media_tablet {
        text-align: center;
        padding-bottom: 30px;
      }
    }
  }
}
.salon_profile {
  &__list {
    @include width-s(500);
    margin: 0 auto;
    @include media_tablet {
      @include width-d(800);
    }
  }
  &__item {
    border-top: 1px solid $color-g;
    cursor: pointer;
    &.end {
      border-bottom: 1px solid $color-g;
    }
    .acd-text {
      @include padding-s(10, 20);
      @include media_tablet {
        @include padding-d(10, 100)
      }
    }
    .diarylist {
      ul {
        li {
          padding-bottom: 14px;
          font-size: 12px;
          @include media_desktop {
            font-size: 16px;
          padding-bottom: 18px;
          }
        }
      }
    }
  }
  &__title {
      font-size: 14px;
      text-align: center;
      margin: 0;
  }
}

.salon-info {
  &__text {
    width: calc(400/ 575 * 100%);
    margin:0 auto;
    position: relative;
    margin-bottom: calc(50 / 575 * 100%);
    @include media_desktop {
      width: calc(600/ 1150 * 100%);
      margin-bottom: calc(100 / 1150 * 100%);
      text-align: center;
    }
    img {
      width: 18px;
      @include media_desktop {
        width: 24px;
      }
    }
  }
}

//spa menu
.spa_menu {
  &__info {
    font-size: 12px;
  }
}

.salon-menu {
  @include padding-s(0, 20);
  margin-bottom: calc(100 / 575 * 100%);
  @include media_tablet {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    @include padding-d(50, 50);
    margin: 0;
    &__rev {
      flex-direction: row-reverse;
    }
  }
  &.end {
    margin-bottom: 0;
  }
  & &__item {
    &__svg {
      @include width-s(500);
      margin-bottom: calc(10 / 575 * 100%);
      @include media_desktop {
        @include width-d(1100);
      }
      &__rev {
          margin-left: auto;
          padding-left: calc(40 / 575* 100%);
          @include media_desktop {
            padding-left: calc(10 / 1150* 100%);
          }
      }
      span {
        font-size: 2rem;
        @include media_desktop {
          font-size: 2.4rem;
        }
      }
    }
    &__img {
      position: relative;
      @include width-s(470);
      margin-bottom: calc(10 / 575 * 100%);
      @include media_desktop {
        @include width-d(1100);
      }
      img {
        border-radius: 25px;
      }
      &__rev {
        margin-left: auto;
      }
    }
    &__text {
      @include width-s(470);
      h3 {
        font-size: 1.2rem;
        @include media_tablet {
          font-size: 1.4rem;
          margin-bottom: 12px;
          @include media_desktop {
            font-size: 1.6rem;
          }
        }
      }
      @include media_tablet {
        @include width-d(400);
      }
      &__rev {
        margin-left: auto;
        @include media_tablet {
          margin-left: 0;
        }
      }
    }
  }
}
.p-s_menu {
  @include media_tablet {
    padding-top: calc(40 / 1150 * 100%);
  }
  & &__item {
    @include padding-s(20, 20);
    position: relative;
    @include media_tablet {
      @include padding-d(50, 100);
    }
  }
}
.spamenu {
  border: 4px solid $color-g;
  border-image: linear-gradient(to right, $color-g 0%, $color-3 100%);
  border-image-slice: 1;
  padding: 20px;
  margin: 20px 0;
}

.menu_label {
  width: 140px;
  position: absolute;
  left: 30px;
  padding: 10px 16px;
  text-align: center;
  @include media_tablet {
    left: 11%;
  }
}

.menu_label_1 {
  background-image: url("../img/spa/menu/menu_label.jpg");
}
.menu_label_2 {
  background-image: url("../img/spa/menu/menu_label2.jpg");
}

.menulist {
  border-top: 1px solid $color-4;
  border-bottom: 1px solid $color-4;
  cursor: pointer;
}

.acd-title {
  position: relative;
  padding: 20px 50px 20px 0;
  color: $color-text;
  @include media_desktop {
    font-size: 16px;
  }
  &:after {
    content: "";
    position: absolute;
    right: 25px;
    top: 38%;
    transition: all 0.2s ease-in-out;
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid $color-text;
    border-right: 2px solid $color-text;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  &.open:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 45%;
  }
}
.acd-text {
  display: none;
}

.yoyaku-policy {
  padding-top: 10px;
  margin-bottom: 40px;
  border-top: 1px solid;
  &:last-child {
    border-bottom: none;
  }
}

//ファーストコース
.first_content {
  &__flex {
    margin-bottom: calc(100 / 575 * 100%);
    @include media_tablet {
      display: flex;
      justify-content: space-around;
      margin-bottom: calc(150 / 1150 * 100%);
      flex-wrap: wrap;
    }
  }
  &__item {
    position: relative;
    padding: calc(100 / 575 * 100%) 0;
    @include media_tablet {
      width: 50%;
      padding: calc(100 / 1150 * 100%) 0;
    }
  }
  &__img {
    @include width-s(350);
    margin: 0 auto calc(40 / 575 * 100%);
    @include media_tablet {
      @include width-d(600);
      margin: 0 auto calc(100 / 1150 * 100%);
    }
  }
  &__text {
    h2, h3 {
      text-align: center;
      padding-top: 10px;
      margin-bottom: 30px; 
    }
  }
}

//月限定コース limited_edition
.limited {
  &__bg {
    background: $spa-bg_color2;
    padding: calc(100 / 575 * 100%) 0;
    @include media_tablet {
      padding: calc(100 / 1150 * 100%) 0;
    }
  }
  &__item {
    position: relative;
  }
  &__img {
    @include width-s(500);
    padding-bottom: calc(60 / 575 * 100%);
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    opacity: 0;
    @include media_tablet {
      @include width-d(700);
      padding-bottom: calc(50 / 1150 * 100%);
      margin-left: calc(150 / 1150 * 100%);
      &.rev {
        margin-left: auto;
        margin-right: calc(150 / 1150 * 100%);
      }
    }
    &.show {
      opacity: 1;
      &::before {
        animation: img-left-slide 2s cubic-bezier(.4, 0, .2, 1) forwards;
        background: $color-bg_1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom:0;
        z-index: 10;
      }
    }
  }
  &__sen {
    @include width-s(350);
    position: absolute;
    top: 60%;
    left: 6%;
    opacity: 0;
    z-index: 30;
    @include media_tablet {
      @include width-d(550);
      top: 45%;
      left: 49%;
      &.rev {
        top: 34%;
        left: 13%;
      }
    }
    path {
      fill: none;
      stroke: $color-rb; 
      stroke-width: 2px;
    }
    &.show {
      opacity: 1;
      path {
        animation: sen1 1.5s ease-out 2s both;
        stroke-dasharray: 1144.4970703125px;
      }
    }
  }
  &__flex {
    text-align: center;
    @include media_tablet {
      display: flex;
      justify-content: center;
      margin-bottom: calc(50 / 1150 * 100%);
      text-align: left;
    }
  }
  &__title {
    @include media_tablet {
      @include width-d(420);
    }
  }
  &__svg {
    @include width-s(550);
    margin: 0 auto calc(30 / 575 * 100%);
    p {
      font-size: 1.8rem;
    }
    @include media_tablet {
      @include width-s(500);
      margin: 0;
      p {
        margin-bottom: 0;
      }
    }
  }
  &__time {
    @include media_tablet {
      @include width-d(420);
    }
    p {
      padding-bottom: calc(50 / 575 * 100%);
      @include media_tablet {
        padding-bottom: 0;
        margin: 0;
      }
    }
  }
  &__text {
    @include media_tablet {
      &.t-box {
        @include width-d(830);
      }
    }
  }
}

// カウンセリング
.counseling_svg {
  @include width-s(150);
  position: absolute;
  top: 0;
  left: 0;
  fill: $color-4;
  @include media_tablet {
    @include width-d(150);
    left: 10%;
  }
  &.rev {
    left: 77%;
    @include media_tablet {
      top: 12%;
      left: 77%;
    }
  }
}
// ヘナカラー講座
.lesson_menu {
  &__title {
    position: relative;
  }
  &__item {
    @include media_tablet {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      align-items: flex-end;
      margin-bottom: calc(50 / 1150 * 100%);
    }
  }
  &__img {
    @include width-s(450);
    padding-bottom: calc(100 / 575 * 100%);
    margin-left: auto;
    @include media_tablet {
      @include width-d(700);
      padding-bottom: 0;
    }
    img {
      border-radius: 48px;
    }
  }
  &__svg {
    @include width-s(350);
    fill: $color-4;
    margin: 0 auto;
    @include media_tablet {
      @include width-d(400);
      margin: 0 0 calc(100 / 1150 * 100%) calc(50 / 1150 * 100%);
    }
  }
  &__sen {
    @include width-s(360);
    position:  absolute;
    top: 1%;
    left: 11%;
    opacity: 0;
    @include media_tablet {
      @include width-d(550);
      top: 5%;
      left: 25%;
    }
    path {
      fill: none;
      stroke: $color-4; 
      stroke-width: 2px;
    }
    &.show {
      opacity: 1;
      path {
        animation: sen1 1s ease-out 1s both;
        stroke-dasharray: 972.2770385742188px;
      }
    } 
  }
}

/*
キャンセルポリシー
*/

/* セルフケア self-care */
.self-care-tool {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: calc(50 / 575* 100%);
  @include media_desktop {
    padding-bottom: calc(50 / 1150* 100%);
  }
  &__item {
    @include width-s(250);
    margin: 0 auto;
    margin-bottom: calc(50 / 575 * 100%);
    @include media_desktop {
      @include width-d(300);
      margin-bottom: calc(100 / 1150 * 100%);
    }
    img {
      margin-bottom: calc(50 / 575 * 100%);
      @include media_desktop {
        margin-bottom: calc(100 / 1150 * 100%);
      }
    }
  }
}   
.self-care-step {
  display: flex;
  flex-direction: column;
  @include media_desktop {
    flex-direction: row;
    flex-wrap: wrap
  }
  &__item {
    @include width-s(400);
    margin: 0 auto;
    margin-bottom: calc(100 / 575 * 100%);
    @include media_tablet {
      @include width-d(600);
    }
    @include media_desktop {
      @include width-d(350);
      margin-bottom: calc(50 / 1150 * 100%);
    }
  }
  &__btn {
    margin-top: 42px;
  }
  &__title {
    text-align: center;
    margin-bottom: calc(50 / 575 * 100%);
    @include media_desktop {
      margin-bottom: calc(100 / 1150 * 100%);
    }
    h3 {
      font-size: 18px;
    }
    span {
      font-size: 2rem;
    }
  }
  img {
    margin-bottom: calc(50 / 575 * 100%);
    @include media_desktop {
      margin-bottom: calc(100 / 1150 * 100%);
    }
  }
  .hot-water {
    padding: 20px;
    border: 2px solid $color-text;
    margin: 20px;
    text-align: center;
    line-height: 1.8;
    font-weight: bold;
  }
}

/* youtube動画 */
.youtube-video-category {
  display: flex;
  flex-direction: column;
  @include media_desktop {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  &__item {
    @include width-s(500);
    margin: 0 auto;
    margin-bottom: calc(80 / 575 * 100%);
    @include media_desktop {
      @include width-d(500);
      margin-bottom: calc(100 / 1150 * 100%);
    }
  }
}
.yt_video::before {
	position: absolute;
	font-family: "Font Awesome 5 Free";
	content: "\f144";
	font-weight:400;
	color: $color-rb;
	font-size: 70px;
	top: 50%;
	left: 50%;
	transform : translate(-50%,-50%);
	opacity: .90;
	transition:.5s;
}
/*
メリット
*/
.merit li {
  margin-bottom: 12px;
  font-size: 12px;
  @include media_desktop {
    font-size: 16px;
  }
}

/*
faq
*/
.faq-list {
  @include width-s(450);
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include media_desktop {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  &__item {
    margin-bottom: calc(30 / 575 * 100%);
    @include media_desktop {
      width: 40%;
      margin-bottom: calc(80 / 1150 * 100%);
    }
    a {
      width: 100%;
      color: #fff;
      padding: 20px 10px;
      cursor: pointer;
    }
  }
}
.faq-text {
  @include width-s(450);
  margin: 0 auto;
  @include media_desktop {
    @include width-d(800);
  }
}
.faq-text__line {
  border-top: 1px solid $color-text;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
}
.faq-q {
  display: inline-block;
  font-size: 1.2rem;
  margin-bottom: 10px;
  @include media_tablet {
    font-size: 1.5rem;
  }
}
.faq-a {
  color: #ff1e1e;
  font-size: 1.2rem;
  @include media_tablet {
    font-size: 1.5rem;
  }
}
/* about-us */
.about-flex {
  display: flex;
  flex-direction: column;
  @include media_desktop {
    flex-direction: row;
  }
  .about-img {
    @include media_desktop {
      @include width-d(400);
      margin-bottom: calc(50 / 1150 * 100%);
    }
    &__1 {
      @include width-s(300);
      margin-left: auto;
      margin-right: calc(30 / 575 * 100%);
      margin-bottom: calc(50 / 575 * 100%);
      @include media_desktop {
        @include width-d(800);
        margin-right: 0;
        margin-bottom: calc(250 / 1150 * 100%);
      }
    }
    &__2 {
      @include width-s(300);
      margin-top: calc(-100 / 575 * 100%);
      margin-left: calc(35 / 575 * 100%);
      @include media_desktop {
        @include width-d(700);
      }
    }
  }

}
/*
コラム
*/
.column-news {
  padding-left: 0;
}
.column-list {
  list-style: none;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #666;
  a {
    color: #000;
  }
  &:first-child {
    border-top: 1px solid #666;
    padding-top: 12px;
  }
  &_title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
    @include media_desktop {
      font-size: 18px;
    }
  }
  &_disec {
    margin-bottom: 0;
    font-size: 10px;
    @include media_tablet {
      font-size: 14px;
    }
  }
  &_data {
    margin-bottom: 0;
    font-size: 10px;
    color: #666;
    @include media_tablet {
      font-size: 14px;
    }
  }
}
.vol-no {
  font-family: sudestada, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  @include media_desktop {
    font-size: 60px;
  }
}
.vol-title {
  color: #5f5248;
}
.vol-text p {
  color: #887667;
}

/*howtobuy*/
.bg-howtobuy {
  background: $color-rb;
}

/*
サイトマップ
*/
.sitemap {
  ul {
    list-style: none;
    padding-inline-start: 0;
  }
  li {
    padding-bottom: 16px;
    font-size: 14px;
    @include media_desktop {
      font-size: 16px;
    }
  }
  a {
    position: relative;
    display: inline-block;
    transition: .3s;
    color: $color-text; 
    &:active {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  &_footer {
    a {
      color: $color-text;
      &:active {
        font-weight: bold;
      }
    }
    .category_name {
      padding-bottom: 16px;
    }
    li {
      padding-bottom: 12px;
      @include media_desktop {
        font-size: 14px;
      }
    }
  }
}
/* contact お問い合わせ */
.ec-contactRole {
  color: $color-text;
}
.ec-pageHeader h1 {
  padding: 12px 0 12px;
  margin: 0 0 24px;
  font-size: 1.2rem;
  color: $color-text;
}
.contact-title {
  text-align: center;
  margin-top: calc(50 / 575 * 100%);
  @include media_desktop {
    margin-top: calc(100 / 1150 * 100%);
  }
  h1 {
    color: $color-text;
  }
}
.contact-h2 {
  font-size: 1.2rem;
  @include media_desktop {
    font-size: 1.4rem;
  }
}
.contact-text {
  @include padding-s(60, 20);
  margin-bottom: calc(40 / 575 * 100%);
  @include media_desktop {
    @include padding-d(80, 50);
    margin-bottom: calc(80 / 1150 * 100%);
  }
  &__item:not(:last-child) {
    margin-bottom: calc(60 / 575 * 100%);
    @include media_desktop {
      margin-bottom: calc(80 / 1150 * 100%);
    }
  }
}
/*
商品一覧

Styleguide 13.0
*/
.ec-select {
  select {
    max-width: 100%;
  } 
  .form-control {
    font-size: 12px;
    @include media_tablet {
      font-size: 16px;
    }
  }
}

// 商品詳細
.btn-detail_list {
  color: $color-text;
  &:hover {
    color: $color-text;
    font-weight: bold;
    text-decoration: underline;
  }
  &:focus {
    color: $color-text;
    text-decoration: underline;
    box-shadow: none;
  }
}
.free-list {
  font-size: 12px;
  @include media_tablet {
    font-size: 14px;
  }
}

/*
注文

Styleguide 15.0
*/
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
  font-size: 10px;
  @include media_tablet {
    font-size: 14px;
  }
  @include media_desktop {
    font-size: 16px;
  }
  a {
    color: #5d5d5d;
  }
}
.ec-cartRow__unitPrice,
.ec-cartRow__sutbtotalSP {
  font-size: 12px;
  @include media_desktop {
    font-size: 16px;
  }
}
.ec-cartHeader,
.ec-cartHeader .ec-cartHeader__label {
  background: #ffffff;
}

.cart-shopping__text {
  color: #de5d50;
}
.ec-customerRole,
.ec-cartCompleteRole {
  color: $color-text;
}
/*
注文履歴

Styleguide 16.0
*/
.ec-navlistRole,
.ec-welcomeMsg, 
.ec-orderRole,
.ec-historyRole .ec-historyRole__contents {
  color: $color-text;
}
.ec-orderMail .ec-orderMail__link a {
  color: $color-link;
}
.ec-orderMail .ec-orderMail__link a:hover {
  color: $color-link;
  font-weight: bold;
}
/*
住所一覧

Styleguide 17.0
*/

/*
パスワード再発行

Styleguide 18.0
*/

/*
フォーム

Styleguide 19.0
*/
.ec-registerRole {
  color: $color-text;
}

/*
404ページ

Styleguide 20.0
*/

/*
退会手続き

Styleguide 21.0
*/

/*
会員情報編集完了

Styleguide 22.0
*/